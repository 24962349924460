import Classy from './Classy.svelte';

const target = document.createElement('div');
document.body.append(target);
const app = new Classy({target});

document.addEventListener('DOMContentLoaded', () => {
  const ct = document.getElementById('classyToggle');
  if (ct) ct.addEventListener('click', e => {
    e.preventDefault();
    app.toggle();
  })
});

