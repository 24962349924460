<script>
  // each data-ptn-name contains:
  //   name
  // each data-ptn-settings contains:
  // [
  //   {
  //     name: 'Background',
  //     opts: [['Dark Purple', 'dark-purple'], ]
  //     selector: ':scope',
  //   },
  //   {
  //     name: 'Image blend',
  //     opts: [1,2,3],
  //     selector: '.bg-image',
  //   },
  // ]
  // Extract the current thing, if set, to item.model

  let shown = false;
  export function toggle() {
    shown = !shown;
  }

  // Find all the things we can tweak.
  let allParts = [];
  [].forEach.call(document.body.querySelectorAll("[data-ptn-name]"), (el) => {
    if (!el.dataset.ptnType || !el.dataset.ptnName) {
      console.log("skipping", el, el.dataset);
      return;
    }

    // One part, may have many settings.
    let settingsForThisType = window.ptnPartSettings[el.dataset.ptnType];
    if (!settingsForThisType) {
      console.warn(
        "Missing settings in ptnPartSettings for type ",
        el.dataset.ptnType,
      );
      return;
    }

    let partSettings = [];
    settingsForThisType.forEach((src) => {
      let setting = Object.assign({}, src);
      setting.el = setting.selector ? el.querySelector(setting.selector) : el;
      if (!setting.el) {
        // console.log("Failed to find", {selector: setting.selector, parent: el, });
        return null;
      }
      setting.model = "";
      if (setting.style) {
        setting.model = setting.el.style[setting.style];
      } else {
        setting.opts.forEach(([value]) => {
          if (setting.el.classList.contains(value)) {
            setting.model = value;
          }
        });
      }

      setting.originalValue = setting.model;
      partSettings.push(setting);
    });
    allParts.push({
      name: el.dataset.ptnName,
      el,
      settings: partSettings,
    });
  });

  let selectedIdx = null,
    selectedPtn,
    lastIdx = null;

  function updateSelectedPtn(selectedIdx) {
    selectedPtn = selectedIdx === null ? null : allParts[selectedIdx];
    // console.log('SEL' , selectedPtn);
    if (lastIdx !== selectedIdx && selectedPtn) {
      selectedPtn.el.scrollIntoView({ behavior: "smooth" });
      lastIdx = selectedIdx;
    }
  }
  $: updateSelectedPtn(selectedIdx);

  function tweakIt(setting) {
    let el = setting.el;
    if (setting.style) {
      el.style[setting.style] = setting.model;
    } else {
      setting.opts.forEach(([value]) => {
        if (setting.model === value) {
          el.classList.add(value);
        } else {
          el.classList.remove(value);
        }
      });
      // Hack for colourways
      if (
        ["Page Background", "Box Background", "Box Shape"].includes(
          setting.name,
        )
      ) {
        // Recalculate colourway
        let pageSetting = selectedPtn.settings.find(
          (s) => s.name === "Page Background",
        );
        let boxSetting = selectedPtn.settings.find(
          (s) => s.name === "Box Background",
        );
        let boxShape = selectedPtn.settings.find((s) => s.name === "Box Shape");
        let colourway =
          "colourway-" +
          (boxSetting.model || pageSetting.model).replace(/^bg-/, "");
        let boxDiv = selectedPtn.el.querySelector(".box-shape-div");
        // Remove existing colourway, add new one
        boxDiv.className =
          boxDiv.className.replace(/colourway-[^ ]+/, "") + " " + colourway;
        if (
          boxShape.model ||
          (boxSetting.model && pageSetting.model != boxSetting.model)
        ) {
          boxDiv.classList.add("box-padding");
        } else {
          boxDiv.classList.remove("box-padding");
        }
      }
    }
    console.log("tweakIt", setting.model, setting.el.classList, setting);
  }
</script>

<div class="classy" class:shown>
  <div style="flex: 0 0 100%;padding:0.25rem 1rem;">
    You can try out some tweaks here, but you'll need to edit the page normally
    to make them stick. Sometimes you just need a bit more visual feedback.
  </div>
  <label
    >Part <select bind:value={selectedIdx}>
      {#each allParts as item, idx}
        <option value={idx}>{item.name}</option>
      {/each}
    </select></label
  >

  {#if selectedIdx !== null}
    {#each selectedPtn.settings as setting}
      {#if setting === null}
        MISSING setting name {setting}
      {:else}
        <label class:updated={setting.originalValue !== setting.model}
          >{setting.name}
          <select bind:value={setting.model} on:change={() => tweakIt(setting)}>
            <option value="">(None)</option>
            {#each setting.opts as o}
              <option value={o[0]}>{o[1] || o[0]}</option>
            {/each}
          </select></label
        >
      {/if}
    {/each}
  {/if}
</div>

<style lang="scss">
  .classy {
    color: #eee;
    font-size: 0.875rem;

    display: none;
    flex-wrap: wrap;
    &.shown {
      display: flex;
    }

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    background: #333;
    border-top: solid 1px #111;

    // a, a:visited { color: white; }
    // a:hover, a:active { color: #88ddff; }

    label {
      padding: 0.25rem 1rem;
      flex: 1 0 auto;
      &.updated {
        font-weight: bold;
        color: #faab46;
        background: #484848;
      }
    }
  }
</style>
